<template>
  <section>
    <div class="header fa computer">
      <div class="header_module fa sb">
        <img
          v-show="$i18n.locale == 'zh'"
          class="header_logo"
          :src="dataInfo.top_logo"
          alt=""
        />
        <img
          v-show="$i18n.locale == 'en'"
          class="header_logo"
          :src="dataInfo.top_logo_en"
          alt=""
        />
        <div class="fa">
          <div
            class="header_btn faj"
            :class="tableIndex == 1 ? 'header_active' : ''"
            @click="tableClick('1', '1')"
          >
            {{ $t("language.index") }}
          </div>
          <div
            class="header_btn faj"
            :class="tableIndex == 2 ? 'header_active' : ''"
            @click="tableClick('2', '1')"
          >
            {{ $t("language.productCenter") }}
          </div>
          <div
            class="header_btn faj"
            :class="tableIndex == 3 ? 'header_active' : ''"
            @click="tableClick('3', '1')"
          >
            {{ $t("language.newsTendency") }}
          </div>
          <div
            class="header_btn faj"
            :class="tableIndex == 4 ? 'header_active' : ''"
            @click="tableClick('4', '1')"
          >
            {{ $t("language.aboutUs") }}
          </div>
          <div @click="toShop" class="header_btn faj">
            {{ $t("language.officialMall") }}
          </div>
          <div @click="languageClick" class="header_btn faj">
            <span v-show="$i18n.locale == 'zh'">LANGUAGE</span>
            <span v-show="$i18n.locale == 'en'">语言</span>
            <img class="header_down" src="../assets/images/down.png" alt="" />
            <div v-if="languageStatus" class="language">
              <div
                @click.stop="switchLanguage('1')"
                class="language_item faj"
                :class="$i18n.locale == 'zh' ? 'language_active' : ''"
              >
                中文
              </div>
              <div
                @click.stop="switchLanguage('1')"
                class="language_item faj"
                :class="$i18n.locale == 'en' ? 'language_active' : ''"
              >
                ENGLISH
              </div>
            </div>
          </div>
          <div class="header_cut"></div>
          <div class="fa">
            <img src="../assets/images/phone.png" class="header_icon" alt="" />
            <div>
              <div class="header_text">{{ $t("language.telephone") }}</div>
              <div class="header_phone">{{ dataInfo.service_tel }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="phone">
      <div class="headerP fa sb">
        <img
          class="headerP_logo"
          src="../assets/images/header_logo.png"
          alt=""
        />
        <img
          @click="tablePClick"
          src="../assets/images/header_moreP.png"
          alt=""
        />
        <div v-if="tableP_status" class="headerP_table">
          <div
            class="headerP_item"
            :class="tableIndex == 1 ? 'headerP_active' : ''"
            @click="tableClick('1', '2')"
          >
            {{ $t("language.index") }}
          </div>
          <div
            class="headerP_item"
            :class="tableIndex == 2 ? 'headerP_active' : ''"
            @click="tableClick('2', '2')"
          >
            {{ $t("language.productCenter") }}
          </div>
          <div
            class="headerP_item"
            :class="tableIndex == 3 ? 'headerP_active' : ''"
            @click="tableClick('3', '2')"
          >
            {{ $t("language.newsTendency") }}
          </div>
          <div
            class="headerP_item"
            :class="tableIndex == 4 ? 'headerP_active' : ''"
            @click="tableClick('4', '2')"
          >
            {{ $t("language.aboutUs") }}
          </div>
          <div @click="toShop" class="headerP_item">
            {{ $t("language.officialMall") }}
          </div>
          <div class="headerP_opt">
            <div class="faj">
              <div class="fx">
                <div
                  @click="switchLanguage('2')"
                  class="headerP_language faj"
                  :class="$i18n.locale == 'zh' ? 'headerP_choose' : ''"
                >
                  中文
                </div>
                <div
                  @click="switchLanguage('2')"
                  class="headerP_language faj"
                  :class="$i18n.locale == 'en' ? 'headerP_choose' : ''"
                >
                  ENGLISH
                </div>
              </div>
            </div>
          </div>
          <img
            src="../assets/images/header_closeP.png"
            class="headerP_close"
            @click="tablePClick"
            alt=""
          />
        </div>
      </div>
    </div>
    <a-carousel v-show="tableIndex == 1" autoplay class="computer">
      <div v-for="(item, index) in dataInfo.banner_list" :key="index">
        <img :src="item.image" class="swiper" alt="" />
      </div>
    </a-carousel>
    <div v-show="tableIndex == 1">
      <a-carousel autoplay class="phone">
        <div v-for="(item, index) in dataInfo.banner_list" :key="index">
          <img :src="item.image" class="swiperP" alt="" />
        </div>
      </a-carousel>
    </div>
    <img
      v-show="tableIndex == 2"
      :src="dataInfo.product_1"
      style="display: block; width: 100%"
      alt=""
    />
    <img
      v-show="tableIndex == 3"
      :src="dataInfo.product_2"
      style="display: block; width: 100%"
      alt=""
    />
    <img
      v-show="tableIndex == 4"
      :src="dataInfo.product_3"
      style="display: block; width: 100%"
      alt=""
    />
    <router-view></router-view>
    <div class="footer">
      <div class="footer_module moduleP">
        <div class="footer_header paddingP fa sb">
          <img
            v-show="$i18n.locale == 'zh'"
            class="footer_logo"
            :src="dataInfo.bottom_logo"
            alt=""
          />
          <img
            v-show="$i18n.locale == 'en'"
            class="footer_logo"
            :src="dataInfo.bottom_logo_en"
            alt=""
          />
          <div class="fa">
            <img
              src="../assets/images/phone_w.png"
              class="footer_image"
              alt=""
            />
            <div>
              <div class="footer_text">{{ $t("language.telephone") }}</div>
              <div v-if="dataInfo != ''" class="footer_phone">
                {{ dataInfo.service_tel }}
              </div>
            </div>
          </div>
        </div>
        <div class="footer_data fx">
          <div class="paddingP f1">
            <div v-if="dataInfo != ''" class="footer_item fx">
              <img
                src="../assets/images/footer_i1.png"
                class="footer_icon"
                alt=""
              />{{ $t("language.addressTitle") }}：
              <span v-show="$i18n.locale == 'zh'">{{ dataInfo.address }}</span>
              <span v-show="$i18n.locale == 'en'">{{
                dataInfo.address_en
              }}</span>
              <!-- {{$t("language.addressDetail")}} -->
            </div>
            <div v-if="dataInfo != ''" class="footer_item fa">
              <img
                src="../assets/images/footer_i2.png"
                class="footer_icon"
                alt=""
              />{{ $t("language.emailTitle") }}：{{ dataInfo.email }}
            </div>
            <div v-if="dataInfo != ''" class="footer_item fa">
              <img
                src="../assets/images/footer_i3.png"
                class="footer_icon"
                alt=""
              />{{ $t("language.wechatTitle") }}：{{ dataInfo.wechat }}
            </div>
            <div v-if="dataInfo != ''" class="footer_item fa">
              <img
                src="../assets/images/footer_i4.png"
                class="footer_icon"
                alt=""
              />QQ：{{ dataInfo.tencent_qq }}
            </div>
            <div v-if="dataInfo != ''" class="footer_item fa">
              <img
                src="../assets/images/footer_i5.png"
                class="footer_icon"
                alt=""
              />WhatsApp：{{ dataInfo.whats_app }}
            </div>
          </div>
          <div class="footer_code computer">
            <img :src="dataInfo.qq_image" class="footer_QR" alt="" />
            <div class="footer_title">{{ $t("language.addQQ") }}</div>
          </div>
          <div class="footer_code computer">
            <img :src="dataInfo.wechat_image" class="footer_QR" alt="" />
            <div class="footer_title">{{ $t("language.addwechat") }}</div>
          </div>
        </div>
      </div>
      <div class="footer_copyright">
        <div class="module fa sb wp moduleP paddingP">
          <div>版权所有 CopyRight © 2021 All Rights Reserved</div>
          <div class="fa">
            <img
              class="footer_tag"
              src="../assets/images/copyright.png"
              alt=""
            />
            {{ dataInfo.beian }}
          </div>
        </div>
      </div>
    </div>
    <div class="opt optP">
      <div v-if="alertStatus" class="opt_module">
        <img v-if="alertQR != ''" class="opt_image" :src="alertQR" alt="" />
        <div v-if="alertPhone != ''" class="opt_phone">{{ alertPhone }}</div>
      </div>
      <img
        @click="pointClick(1, dataInfo.qq_image)"
        class="opt_item"
        src="../assets/images/opt_i2.png"
        alt=""
      />
      <img
        @click="pointClick(2, dataInfo.service_tel)"
        class="opt_item"
        src="../assets/images/opt_i3.png"
        alt=""
      />
      <img
        @click="pointClick(1, dataInfo.wechat_image)"
        class="opt_item"
        src="../assets/images/opt_i4.png"
        alt=""
      />
      <img
        @click="toTop"
        class="opt_item"
        src="../assets/images/opt_i5.png"
        alt=""
      />
    </div>
  </section>
</template>

<script>
import { get_header_footer_data } from "@/assets/api/api";
export default {
  data() {
    return {
      dataInfo: "",
      tableIndex: 1,
      tableP_status: false,
      languageStatus: false,
      alertQR: "",
      alertPhone: "",
      alertStatus: false,
    };
  },
  watch: {
    $route(to) {
      if (to.path == "/index") {
        this.tableIndex = 1;
      } else if (to.path == "/product") {
        this.tableIndex = 2;
      } else if (to.path == "/news") {
        this.tableIndex = 3;
      } else if (to.path == "/about") {
        this.tableIndex = 4;
      }
    },
  },
  methods: {
    toShop() {
      window.location.href = this.dataInfo.shop_url;
    },
    pointClick(index, text) {
      this.alertQR=""
      this.alertPhone=""
      if (index == 1) {
        this.alertQR = text;
      } else {
        this.alertPhone = text;
      }
      this.alertStatus = true;
      clearTimeout(this.setTime);
      this.setTime = setTimeout(() => {
        this.alertStatus = false;
      }, 3000);
    },
    tablePClick() {
      if (this.tableP_status) {
        this.tableP_status = false;
      } else {
        this.tableP_status = true;
      }
    },
    languageClick() {
      if (this.languageStatus) {
        this.languageStatus = false;
      } else {
        this.languageStatus = true;
      }
    },
    switchLanguage(type) {
      if (type == "1") {
        this.languageClick();
      }
      this.$i18n.locale == "zh"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "zh");
      localStorage.setItem("languageSet", this.$i18n.locale);
    },
    tableClick(index, type) {
      if (type == 2) {
        this.tableP_status = false;
      }
      this.tableIndex = index;
      if (index == 1) {
        this.$router.push("/index");
      } else if (index == 2) {
        this.$router.push("/product");
      } else if (index == 3) {
        this.$router.push("/news");
      } else if (index == 4) {
        this.$router.push("/about");
      }
      this.$forceUpdate();
    },
    getSite() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
    },
    toTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    get_header_footer_data() {
      get_header_footer_data().then((res) => {
        if (res.data.code == 1) {
          this.dataInfo = res.data.data;
          let address = {
            address: res.data.data.address,
            address_image: res.data.data.address_image,
          };
          localStorage.setItem("address", JSON.stringify(address));
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.getSite, true);
    this.$router.push("/index");
    this.get_header_footer_data();
  },
};
</script>

<style coped>
.header {
  position: relative;
  height: 90px;
  z-index: 10;
}
.header_module {
  width: 1200px;
  margin: 0 auto;
}
.header_logo {
  height: 40px;
}
.header_btn {
  position: relative;
  height: 40px;
  width: 110px;
  color: #333;
  font-size: 14px;
  background: #fff;
  margin-left: 15px;
  cursor: pointer;
}
.header_active {
  color: #fff;
  background: #004e9f;
  border-radius: 5px;
}
.header_cut {
  height: 20px;
  width: 1px;
  background: #efefef;
  margin: 0 15px;
}
.header_down {
  width: 15px;
  margin-left: 5px;
}
.header_icon {
  width: 40px;
  margin-right: 5px;
}
.header_text {
  font-size: 14px;
  color: #004e9f;
}
.header_phone {
  color: #004e9f;
  font-size: 18px;
  font-weight: bold;
}
.language {
  position: absolute;
  width: 150px;
  top: 50px;
  left: 0;
  background: #fff;
}
.language_item {
  height: 40px;
  color: #333;
  font-size: 14px;
}
.language_active {
  color: #fff;
  background: #004e9f;
}

.swiper {
  width: 100%;
  display: block;
}

.footer {
  position: relative;
  background: #004e9f;
}
.footer_module {
  width: 1200px;
  margin: 0 auto;
  padding: 60px 0 20px 0;
}
.footer_header {
  padding: 0 0 30px 0;
  border-bottom: 1px solid #709bc9;
}
.footer_logo {
  height: 40px;
}
.footer_image {
  width: 50px;
  margin-right: 5px;
}
.footer_text {
  font-size: 14px;
  color: #fff;
}
.footer_phone {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.footer_data {
  margin-top: 30px;
}
.footer_item {
  color: #fff;
  font-size: 15px;
  padding: 10px 0;
}
.footer_icon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.footer_code {
  margin-left: 15px;
}
.footer_QR {
  width: 130px;
  height: 130px;
}
.footer_title {
  color: #fff;
  font-size: 15px;
  margin-top: 5px;
  text-align: center;
}

.footer_copyright {
  background: #3371b2;
}
.footer_copyright .module {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  color: #fff;
  font-size: 15px;
}
.footer_tag {
  width: 20px;
  margin-right: 5px;
}

.opt {
  position: fixed;
  width: 48px;
  height: 240px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 30px;
}
.opt_item {
  position: relative;
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: block;
}
.opt_module {
  position: absolute;
  right: 55px;
  top: 0px;
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px #e0e0e0;
}
.opt_image {
  width: 100px;
  height: 100px;
}
.opt_phone{
  font-size: 16px;
  padding: 0 10px;
  font-weight: bold;
}

.headerP {
  height: 80px;
  padding: 0 15px;
  z-index: 10;
}
.headerP_logo {
  height: 40px;
}
.headerP_table {
  position: absolute;
  height: calc(100% - 80px);
  width: 100%;
  top: 80px;
  left: 0;
  background: #004e9f;
  box-sizing: border-box;
  z-index: 5;
}
.headerP_item {
  padding: 15px;
  color: #fff;
  font-size: 13px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}
.headerP_active {
  background: rgba(255, 255, 255, 0.25);
}
.headerP_opt {
  width: 100%;
  position: absolute;
  bottom: 80px;
}
.headerP_language {
  width: 60px;
  height: 30px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  font-size: 13px;
  color: #fff;
  border-radius: 2px;
  margin: 0 5px;
}
.headerP_choose {
  background: #fff;
  color: #004e9f;
}
.headerP_close {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 30px;
  height: 30px;
}
.swiperP {
  width: 100%;
  display: block;
}
</style>